import React from "react";
import { Grid, Link, Typography } from "@mui/material";
import Layout from "../components/layout";

const Privacy = () => {
  return (
    <>
      <Layout title="Privacy Policy" ads={false}>
        <Grid container spacing={2} sx={{ mb: 3 }}>
          <Grid item xs={12} md={8}>
            <Typography component="h1" variant="h3">
              Grabbing a Snack Privacy Policy
            </Typography>

            <Typography component="body1">
              This Privacy Policy describes how your personal information is
              collected, used, and shared when you visit
              https://grabbingasnack.com (the “Site”).
            </Typography>

            <Typography component="h2" variant="h4" sx={{ mt: 2 }}>
              PERSONAL INFORMATION WE COLLECT
            </Typography>

            <Typography component="body1">
              When you visit the Site, we automatically collect certain
              information about your device, including information about your
              web browser, IP address, time zone, and some of the cookies that
              are installed on your device. Additionally, as you browse the
              Site, we collect information about the individual web pages or
              products that you view, what websites or search terms referred you
              to the Site, and information about how you interact with the Site.
              We refer to this automatically-collected information as “Device
              Information.” We collect Device Information using the following
              technologies:
              <ul>
                <li>
                  “Cookies” are data files that are placed on your device or
                  computer and often include an anonymous unique identifier. For
                  more information about cookies, and how to disable cookies,
                  visit{" "}
                  <Link href="http://www.allaboutcookies.org">
                    http://www.allaboutcookies.org
                  </Link>
                  .{" "}
                </li>
                <li>
                  “Log files” track actions occurring on the Site, and collect
                  data including your IP address, browser type, Internet service
                  provider, referring/exit pages, and date/time stamps.
                </li>
                <li>
                  “Web beacons,” “tags,” and “pixels” are electronic files used
                  to record information about how you browse the Site. When we
                  talk about “Personal Information” in this Privacy Policy, we
                  are talking about Device Information.
                </li>
              </ul>
            </Typography>

            <Typography component="h2" variant="h4" sx={{ mt: 2 }}>
              HOW DO WE USE YOUR PERSONAL INFORMATION?
            </Typography>

            <Typography component="body1">
              We use the Device Information that we collect to help us improve
              and optimize our Site (for example, by generating analytics about
              how our customers browse and interact with the Site, and to assess
              the success advertising campaigns).
            </Typography>

            <Typography component="h2" variant="h4" sx={{ mt: 2 }}>
              SHARING YOUR PERSONAL INFORMATION
            </Typography>

            <Typography component="body1">
              We share your Personal Information with third parties to help us
              use your Personal Information, as described above. We also use
              Google Analytics to help us understand how our customers use the
              Site--you can read more about how Google uses your Personal
              Information here:{" "}
              <Link href="https://www.google.com/intl/en/policies/privacy/">
                https://www.google.com/intl/en/policies/privacy/
              </Link>
              . You can also opt-out of Google Analytics here:{" "}
              <Link href="https://tools.google.com/dlpage/gaoptout">
                https://tools.google.com/dlpage/gaoptout
              </Link>
              . Finally, we may also share your Personal Information to comply
              with applicable laws and regulations, to respond to a subpoena,
              search warrant or other lawful request for information we receive,
              or to otherwise protect our rights.
            </Typography>

            <Typography component="h2" variant="h4" sx={{ mt: 2 }}>
              BEHAVIORAL ADVERTISING
            </Typography>

            <Typography component="body1">
              As described above, we use your Personal Information to provide
              you with targeted advertisements or marketing communications we
              believe may be of interest to you. For more information about how
              targeted advertising works, you can visit the Network Advertising
              Initiative's (“NAI”) educational page at{" "}
              <Link href="http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work">
                http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work
              </Link>
              . You can opt out of targeted advertising by:
              <ul>
                <li>
                  <Link href="https://www.google.com/settings/ads/anonymous">
                    Google
                  </Link>{" "}
                </li>
                <li>
                  <Link href="www.aboutads.info">About Ads</Link>, Additionally,
                  you can opt out of some of these services by visiting the
                  Digital Advertising Alliance's opt-out portal at:{" "}
                  <Link href="http://optout.aboutads.info/">
                    http://optout.aboutads.info/
                  </Link>
                  .
                </li>
              </ul>
            </Typography>

            <Typography component="h2" variant="h4" sx={{ mt: 2 }}>
              YOUR RIGHTS
            </Typography>

            <Typography component="body1">
              If you are a European resident, you have the right to access
              personal information we hold about you and to ask that your
              personal information be corrected, updated, or deleted. If you
              would like to exercise this right, please contact us through the
              contact information below. Additionally, please note that your
              information will be transferred outside of Europe, including to
              Canada and the United States.
            </Typography>

            <Typography component="h2" variant="h4" sx={{ mt: 2 }}>
              DATA RETENTION
            </Typography>

            <Typography component="body1">
              Analytics data will be retained for a period of 2 months or
              earlier if you ask us to delete this information.
            </Typography>

            <Typography component="h2" variant="h4" sx={{ mt: 2 }}>
              MINORS
            </Typography>

            <Typography component="body1">
              The Site is not intended for individuals under the age of 18.
            </Typography>

            <Typography component="h2" variant="h4" sx={{ mt: 2 }}>
              CHANGES
            </Typography>
            <Typography component="body1">
              We may update this privacy policy from time to time in order to
              reflect, for example, changes to our practices or for other
              operational, legal or regulatory reasons.
            </Typography>

            <Typography component="h2" variant="h4" sx={{ mt: 2 }}>
              CONTACT US
            </Typography>

            <Typography component="body1">
              For more information about our privacy practices, if you have
              questions, or if you would like to make a complaint, please
              contact us by e-mail at{" "}
              <Link href="mailto:grabbingasnack+privacy@gmail.com">
                grabbingasnack+privacy@gmail.com
              </Link>
              .
            </Typography>
          </Grid>
        </Grid>
      </Layout>
    </>
  );
};
export default Privacy;
